import React, { useState, useEffect } from 'react';
import { MediaMatcher, MediaServerRender } from 'react-media-match';
import tt from '../../helpers/translation';
import './asgard.scss';

import { StandardFilter } from '../filters/standard-filter';

export const Asgard = (props) => {
  const {
    data: { image, text, filters },
    filterStatusMundo,
    filterStatusTipoProducto,
    filterStatusTipoMenu,
    filterStatusTipoServicios,
    filterChange,
    locale,
  } = props;

  const [tags, setTags] = useState(null);

  useEffect(() => {
    const filterTags = filters.map((filter) => filter.tag);
    setTags(filterTags);
  }, []);

  const handleResetFilter = () => {
    props.resetFilter();
  };

  const isMobileTrue = (
    <div className="Asgard-form-container">
      <div className="Asgard-text-line">
        <div className="Asgard-text">{text}</div>
        <div onClick={handleResetFilter} className="Asgard-clear">
          {' '}
          X {tt('Borrar filtros', locale)}
        </div>
      </div>
      <div className="Asgard-forms">
        {filters.map((filter) => (
          <StandardFilter
            locale={locale}
            filterStatusMundo={filterStatusMundo}
            filterStatusTipoProducto={filterStatusTipoProducto}
            filterStatusTipoMenu={filterStatusTipoMenu}
            filterStatusTipoServicios={filterStatusTipoServicios}
            filterChange={filterChange}
            tag={filter.tag}
            options={filter.options}
            tags={tags}
            key={filter.tag}
          />
        ))}
      </div>
    </div>
  );

  const isBrowserTrue = (
    <div className="Asgard-form-container">
      <div className="Asgard-text">{text}</div>
      <div className="Asgard-forms">
        {filters.map((filter) => (
          <StandardFilter
            locale={locale}
            filterStatusMundo={filterStatusMundo}
            filterStatusTipoProducto={filterStatusTipoProducto}
            filterStatusTipoMenu={filterStatusTipoMenu}
            filterStatusTipoServicios={filterStatusTipoServicios}
            filterChange={filterChange}
            tag={filter.tag}
            options={filter.options}
            tags={tags}
            key={filter.tag}
          />
        ))}
      </div>
      <div onClick={handleResetFilter} className="Asgard-clear">
        {' '}
        X {tt('Borrar filtros', locale)}
      </div>
    </div>
  );

  return (
    <div className="asgard-container">
      <div className="standard-box">
        <div className="Asgard-image ">
          <img src={image} alt="logo" />
        </div>
        <MediaServerRender predicted="desktop" hydrated>
          <MediaMatcher mobile={isMobileTrue} tablet={isBrowserTrue} desktop={isBrowserTrue} />
        </MediaServerRender>
      </div>
    </div>
  );
};
