import './standardFilter.scss';

import PropTypes from 'prop-types';
import React from 'react';

import tt from '../../helpers/translation';

export const StandardFilter = (props) => {
  const { tag, options, tags, locale } = props;
  const handleDropDownChange = (event) => {
    const index = event.nativeEvent.target.selectedIndex;
    const name = event.nativeEvent.target[index].value;
    props.filterChange(name, tag);
  };

  return (
    <div className="select-option">
      <select
        onChange={handleDropDownChange}
        className="uppercase"
        name={`${tag}`}
        form={`select-${tag}`}
      >
        {tag === tt('MUNDO', locale) ? (
          <option
            selected={props.filterStatusMundo}
            hidden={!props.filterStatusMundo}
            disabled
            value=""
          >{`${tag}`}</option>
        ) : null}
        {tag === tt('TIPO DE PRODUCTO', locale) ? (
          <option
            selected={props.filterStatusTipoProducto}
            hidden={!props.filterStatusTipoProducto}
            disabled
            value=""
          >{`${tag}`}</option>
        ) : null}
        {tag === tt('TIPO DE MENÚ', locale) ? (
          <option
            selected={props.filterStatusTipoMenu}
            hidden={!props.filterStatusTipoMenu}
            disabled
            value=""
          >{`${tag}`}</option>
        ) : null}
        {tag === tt('TIPO DE ATRACCIÓN', locale) ? (
          <option
            selected={props.filterStatusTipoMenu}
            hidden={!props.filterStatusTipoMenu}
            disabled
            value=""
          >{`${tag}`}</option>
        ) : null}
        {tag === tt('TIPO DE SERVICIO', locale) ? (
          <option
            selected={props.filterStatusTipoServicios}
            hidden={!props.filterStatusTipoServicios}
            disabled
            value=""
          >{`${tag}`}</option>
        ) : null}
        {tag === tt('TIPO DE ESPECTÁCULO', locale) ? (
          <option
            selected={props.filterStatusTipoServicios}
            hidden={!props.filterStatusTipoServicios}
            disabled
            value=""
          >{`${tag}`}</option>
        ) : null}
        {tag === tt('Tipo de actividad', locale) ? (
          <option
            selected={props.filterStatusTipoServicios}
            hidden={!props.filterStatusTipoServicios}
            disabled
            value=""
          >{`${tag}`}</option>
        ) : null}
        {tag === tt('Temática', locale) ? (
          <option
            selected={props.filterStatusTipoMenu}
            hidden={!props.filterStatusTipoMenu}
            disabled
            value=""
          >{`${tag}`}</option>
        ) : null}
        {tag === tt('Temporada', locale) ? (
          <option
            selected={props.filterStatusTipoServicios}
            hidden={!props.filterStatusTipoServicios}
            disabled
            value=""
          >{`${tag}`}</option>
        ) : null}
        {tag === tt('Parque', locale) ? (
          <option
            selected={props.filterStatusTipoMenu}
            hidden={!props.filterStatusTipoMenu}
            disabled
            value=""
          >{`${tag}`}</option>
        ) : null}
        {options.map((option) => (
          <React.Fragment key={option.value}>
            <option value={`${option.label}`}>{`${option.value}`}</option>
          </React.Fragment>
        ))}
      </select>
    </div>
  );
};

StandardFilter.propTypes = {
  options: PropTypes.object.isRequired,
  tag: PropTypes.string.isRequired,
  filterChange: PropTypes.func,
};
