import './distributiva_tiendas_fl.scss';

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Asgard } from '../src/components/Asgard/Asgard';
import { Freya } from '../src/components/Freya/Freya';
import H4 from '../src/components/Titles/TitleH4single/TitleH4single';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Button from '../src/components/Buttons/Buttons';
import Layout from '../src/components/Layout/Layout';
import SEO from '../src/helpers/seo';
import AnounceCovid from '../src/components/AnounceCovid/AnounceCovid';
import BlueArrow from '../src/components/BlueArrow/BlueArrow';
import tt from '../src/helpers/translation';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import Bragi from '../src/components/Bragi/bragi';
import Hod from '../src/components/Hod/Hod';

class DistributivaTiendasFl extends Component {
  state = {
    tipoProducto: '',
    initialFilterStateTipoProducto: true,
  };

  handleFilter = (name, tag) => {
    if (tag === tt('TIPO DE PRODUCTO', this.props.pageContext.locale))
      this.setState({ tipoProducto: name, initialFilterStateTipoProducto: false });
  };

  resetFilter = () => {
    this.setState({
      initialFilterStateMundo: true,
      initialFilterStateTipoProducto: true,
      tipoProducto: '',
    });
  };

  render() {
    const tiendasData = {
      text: this.props.data.tiendasHeaderBlock,
      image: this.props.data.tiendasHeaderImageBlock.localImage.childImageSharp.fluid,
      cards: this.props.data.allTiendasCardsSubBlock.edges,
      breadcrumb: this.props.data.tiendasBreadCrumbBlock,
      promotions: this.props.data.allTiendasPromotedSubBlock.edges,
    };

    const heimdallData = {
      name: tiendasData.text.title.toUpperCase(),
      subtitle: tiendasData.text.subtitle,
      image: {
        url: tiendasData.image,
      },
      imageMobile: {
        url: this.props.data.allTiendasMobileImage.edges[0].node.localImage.childImageSharp.fluid,
      },
    };

    const AsgardData = {
      image:
        'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/images/images/000/009/031/original/fl.png',
      text: `${tiendasData.cards.length} ${tt('tiendas', this.props.pageContext.locale)}`,
      filters: [
        {
          tag: tt('TIPO DE PRODUCTO', this.props.pageContext.locale),
          options: [
            { value: tt('Souvenirs', this.props.pageContext.locale), label: 'souvenirs' },
            { value: tt('Golosinas', this.props.pageContext.locale), label: 'candies' },
            { value: tt('Video Ride', this.props.pageContext.locale), label: 'video_ride' },
            { value: tt('Moda', this.props.pageContext.locale), label: 'fashion' },
            {
              value: tt('Fotografía-atracciones', this.props.pageContext.locale),
              label: 'photo_ride',
            },
            {
              value: tt('Fotografía-especiales', this.props.pageContext.locale),
              label: 'photo_special',
            },
          ],
        },
      ],
    };

    const freyaDataShops = {
      cards: tiendasData.cards
        .map((shop) => ({
          image: shop.node.localImage.childImageSharp.fluid,
          altImage: shop.node.image_alt,
          imgsize: shop.node.imgsize,
          title: shop.node.title,
          description: shop.node.description,
          disabled: shop.node.disabled,
          seeMore: shop.node.seeMore,
          located: shop.node.located.charAt(0).toUpperCase() + shop.node.located.slice(1),
          features: shop.node.features.split(' '),
          buttons: {
            cta: shop.node.buttons.cta,
            size: shop.node.buttons.size,
            color: shop.node.buttons.color,
            ctaText: shop.node.buttons.ctaText,
          },
        }))
        .filter((item) => {
          if (this.state.initialFilterStateTipoProducto === true) return item;
          if (this.state.tipoProducto !== '') {
            return item.features.includes(this.state.tipoProducto.toLowerCase());
          }
        }),
    };

    const buttons = {
      cta: tt('/entradas/ferrari-land', this.props.pageContext.locale),
      size: 'alone',
      color: 'orange',
      ctaText: tt('COMPRAR ENTRADAS', this.props.pageContext.locale),
    };

    const freyaData = {
      title: tt('PROMOCIONES RELACIONADAS', this.props.pageContext.locale),
      cards: tiendasData.promotions.map((shop) => ({
        image: shop.node.localImage.childImageSharp.fluid,
        altImage: shop.node.image_alt,
        title: shop.node.description,
        imgsize: 'promoted',
        buttons: {
          cta: shop.node.cta,
          size: 'alone',
          color: 'white',
          ctaText: shop.node.ctaText,
        },
      })),
    };
    return (
      <Layout
        layoutFromOld
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
      >
        <SEO
          title={this.props.data.allShopsSeoData.edges[0].node._0.title}
          description={this.props.data.allShopsSeoData.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <Hod
          data={this.props.data.allTiendasRichData.edges[0].node.rich_markup}
          url={this.props.pageContext.url}
          img={this.props.data.tiendasHeaderImageBlock.image}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="general-index">
            <div className="shops-content">
              <div className="Asgard-box">
                <Asgard
                  locale={this.props.pageContext.locale}
                  filterStatusTipoProducto={this.state.initialFilterStateTipoProducto}
                  resetFilter={this.resetFilter}
                  filterChange={this.handleFilter}
                  data={AsgardData}
                />
              </div>

              <div className="shops-buy">
                <Button
                  link={buttons.cta}
                  size={buttons.size}
                  color={buttons.color}
                  text={buttons.ctaText}
                />
                <BlueArrow />
                <NeoAesir
                  locale={this.props.pageContext.locale}
                  pageName={this.props.pageContext.pageName}
                  title={this.props.data.allDistTiendasBreadCrumbBlock.edges[0].node.name}
                />
                <AnounceCovid
                  h2
                  text={tt(
                    'EL FUNCIONAMIENTO HABITUAL DE CIERTAS TIENDAS PUEDE VERSE ALTERADO O LIMITADO TEMPORALMENTE EN ATENCIÓN A LAS RESTRICCIONES DERIVADAS DE LAS MEDIDAS PREVENTIVAS COVID-19 IMPLEMENTADAS.',
                    this.props.pageContext.locale
                  )}
                />
              </div>
              <div className="shop-freya-content">
                <Freya data={freyaDataShops} />
              </div>
              {this.props.data.allTiendasVideo.edges[0].node.video ? (
                <div className="video-container">
                  <Bragi url={this.props.data.allTiendasVideo.edges[0].node.video} />
                </div>
              ) : null}
              <div className="shops-content-promo">
                <H4 title={tt('PROMOCIONES RELACIONADAS', this.props.pageContext.locale)} />
                <Freya data={freyaData} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

/* eslint-disable react/require-default-props */
DistributivaTiendasFl.propTypes = {
  ourShopsData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
  }),
};
/* eslint-enable react/require-default-props */

export default DistributivaTiendasFl;

export const pageQuery = ({ data, pathContext: { locale } }) => graphql`
  query tiendasFerrariLandQuery($locale: String!) {
    tiendasHeaderBlock(park: { eq: "FerrariLand" }, lang: { eq: $locale }) {
      title
      subtitle
    }
    allShopsSeoData(filter: { tag: { eq: "tiendas_fl" }, lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allTiendasVideo(filter: { park: { eq: "FerrariLand" }, lang: { eq: $locale } }) {
      edges {
        node {
          video
        }
      }
    }
    tiendasHeaderImageBlock(park: { eq: "FerrariLand" }, lang: { eq: $locale }) {
      image
      localImage {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    allShopsVideo(filter: { park: { eq: "FerrariLand" }, lang: { eq: $locale } }) {
      edges {
        node {
          video
        }
      }
    }
    allTiendasCardsSubBlock(
      filter: { park: { eq: "FerrariLand" }, lang: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          imgsize
          image_alt
          title
          description
          disabled
          seeMore
          located
          features
          buttons {
            cta
            size
            color
            ctaText
          }
        }
      }
    }
    allTiendasMobileImage(filter: { park: { eq: "FerrariLand" }, lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allTiendasPromotedSubBlock(filter: { tag: { eq: "tiendas_fl" }, lang: { eq: $locale } }) {
      edges {
        node {
          ctaText
          cta
          description
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
        }
      }
    }
    allDistTiendasBreadCrumbBlock(filter: { park: { eq: "FerrariLand" }, lang: { eq: $locale } }) {
      edges {
        node {
          destination_url
          name
        }
      }
    }
    allTiendasRichData(filter: { extra: { eq: "FerrariLand" }, lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            og_title
            rich_markup_type
            og_description
          }
        }
      }
    }
  }
`;
